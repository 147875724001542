import React, {useState} from "react"
import Layout from "../components/common/layout"
import TrattamentoCard from "../components/cards/trattamentoCard"
import trattamentiStyles from "../styles/pages/trattamenti.module.scss"
import Promozioni from "../components/home/promozioni"
import { graphql } from 'gatsby'

const TrattamentiPage = ({ data, location }) => {
  const [selectedTab, setSelectedTab] = useState('trattamenti');

 const TabSelector = ({setSelectedTab}) => {
  return (
    <div className={trattamentiStyles.selector}>
      <button className={trattamentiStyles.selectorLeft} style={selectedTab === 'trattamenti' ?  {background:  "linear-gradient(to bottom, #11d4ff, #058d27)", color: "white", opacity:0.8} : {opacity: 1}} onClick={() => setSelectedTab('trattamenti')}>Trattamenti</button>
      <button className={trattamentiStyles.selectorRight} style={selectedTab === 'pacchetti' ?  {background:  "linear-gradient(to bottom, #11d4ff, #058d27)", color: "white",  opacity:0.8} : {opacity: 1}} onClick={() => setSelectedTab('pacchetti')}>Pacchetti</button>
    </div>
  )
}
 
const PacchettiList = ({data}) => {
  return (
      <ul className={trattamentiStyles.trattamenti}>
              {data.allStrapiTrattamento.edges.map(post => (
                (post.node.categoria === 'pacchetto') &&
                <li key={post.node.strapiId}>
                  <TrattamentoCard post={post} />
                </li>
              ))}
      </ul>)
  }

const TrattamentiList = ({data}) => {
    return (
        <ul className={trattamentiStyles.trattamenti}>
                {data.allStrapiTrattamento.edges.map(post => (
                  (post.node.categoria !== 'pacchetto' && post.node.categoria === 'gift_card' && post.node.nome !== 'DUMMY_NON_USARE') &&
                  <li key={post.node.strapiId}>
                    <TrattamentoCard post={post} />
                  </li>
                ))}
                {data.allStrapiTrattamento.edges.map(post => (
                  (post.node.categoria !== 'pacchetto' && post.node.categoria !== 'gift_card') &&
                  <li key={post.node.strapiId}>
                    <TrattamentoCard post={post} />
                  </li>
                ))}
        </ul>)
    }

  return (
    <Layout
      pageMeta={{
        title: "Shop trattamenti",
        keywords: ["massoterapia", "craniosacrale", "Savona", "riflessologia plantare", "reiki", "linfodrenaggio"],
        description:
          "Voucher e buoni regalo per trattamenti singoli e pacchetti disponibili all'acquisto on line.",
        link: "/trattamenti",
      }}
      location={location}
    >
      {/* <Promozioni /> */}
      <h1 className="page-title">shop trattamenti</h1>
      <TabSelector 
        setSelectedTab={setSelectedTab}
      />
      {(selectedTab === 'trattamenti') && <TrattamentiList data={data}/>}
      {(selectedTab === 'pacchetti') && <PacchettiList data={data}/>}
    </Layout>
  )
}

export default TrattamentiPage

export const pageBlogQuery = graphql`
  query TrattamentiPageQuery {
    allStrapiTrattamento(sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          strapiId
          nome
          slug
          descrizione
          categoria
          prezzo
          durata
          promo {
            codice
            sconto
            inizio
            fine
          }
          foto {
            name
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`